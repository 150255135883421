import { Pipe, PipeTransform } from '@angular/core';
import { ServiceType } from '../constants/service-type';

@Pipe({
  name: 'serviceType',
  standalone: true
})
export class ServiceTypePipe implements PipeTransform {

  transform(value: ServiceType): string {
    return ServiceType[value];
  }

}
