import { Pipe, PipeTransform } from '@angular/core';
import { ServiceProviderType } from '../constants/service-provider-type';

@Pipe({
  name: 'serviceProviderType',
  standalone: true
})
export class ServiceProviderTypePipe implements PipeTransform {

  transform(value: ServiceProviderType): string {
    return ServiceProviderType[value];
  }

}
