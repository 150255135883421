import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceProvider } from '../models/service-provider';
import { ServiceTypePipe } from '../pipes/service-type.pipe';
import { ServiceProviderTypePipe } from '../pipes/service-provider-type.pipe';
import { GridModule } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-service-provider-table',
  templateUrl: './service-provider-table.component.html',
  styleUrl: './service-provider-table.component.scss',
  standalone: true,
  imports: [GridModule, CommonModule, ServiceTypePipe, ServiceProviderTypePipe],
})
export class ServiceProviderTableComponent implements OnInit {
  @Input() gridData: ServiceProvider[] = [];

  constructor() { }

  ngOnInit(): void {
  }



}
