import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callback',
  template: `<p>Loading...</p>`,
  standalone: true,
  providers: [AuthService]
})
export class CallbackComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.auth.handleRedirectCallback().subscribe({
      next: (result) => {
        const target = result?.appState?.target || '/';
        this.router.navigate([target]);
      },
      error: (error) => {
        console.error(error);
        this.router.navigate(['/']);
      }
    });
  }
}
