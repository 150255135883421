import { Component } from '@angular/core';
import { CommonModule } from "@angular/common";
import { MenuModule, MenuSize } from '@progress/kendo-angular-menu';
import { IconsModule } from '@progress/kendo-angular-icons';
import { homeIcon, cartIcon, infoCircleIcon, envelopeIcon } from '@progress/kendo-svg-icons';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [MenuModule, CommonModule, IconsModule],

})
export class SidebarComponent {
  public items: any[] = [
    { text: 'Home', svgIcon: homeIcon, path: '' },
    { text: 'Students', svgIcon: cartIcon, path: '/students' },
    { text: 'Programs', svgIcon: infoCircleIcon, path: '/programs' },
    { text: 'Courses', svgIcon: envelopeIcon, path: '/courses' },
    { text: 'Providers', svgIcon: envelopeIcon, path: '/providers' },
    { text: 'Settings', svgIcon: envelopeIcon,  items: [{ text: 'Restaurants', path: '/settings/restaurants' }] },
  ];
  
  public componentSizes: MenuSize[] = ["small", "medium", "large"];
  public selectedComponentSize: MenuSize = "medium";

  constructor(private router: Router) {
  }

  onSelect(event: any): void {
    // Check if the selected item has a direct path for navigation
  if (event.item.path) {
    this.router.navigate([event.item.path]);
  } else if (event.item.items && event.item.items.length > 0) {
    // If the item has child items, you might want to handle it differently.
    // For example, you could expand the submenu or navigate to the first child item's path.
    // This example navigates to the first child item's path as a default action.
    const firstChildItem = event.item.items[0];
    if (firstChildItem && firstChildItem.path) {
      this.router.navigate([firstChildItem.path]);
    }
  }
  }
}