<div class="button-container">
    <button kendoButton (click)="addProvider()">Add Provider</button>
</div>
<kendo-dialog *ngIf="dialogOpened" (close)="close()" [minWidth]="1300" [width]="1300">
    <kendo-dialog-titlebar>
        Service Provider Details
    </kendo-dialog-titlebar>
    <app-service-provider-detail #formComponent (dataSourceUpdated)="close()"></app-service-provider-detail>
    <kendo-dialog-actions>
        <button kendoButton type="submit" (click)="submitForm()" [primary]="true">Submit</button>
        <button kendoButton type="button" (click)="close()">Cancel</button>
    </kendo-dialog-actions>
</kendo-dialog>

<app-service-provider-table [gridData]="dataSource"></app-service-provider-table>