import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ServiceType } from '../constants/service-type';
import { ServiceProviderType } from '../constants/service-provider-type';
import { ServiceProviderService } from '../services/service-provider.service';
import { DialogModule } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-service-provider-detail',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    WindowModule,
    DropDownsModule,
    InputsModule,
    LayoutModule,
    ButtonsModule,
    EditorModule,
    DialogModule
  ],
  templateUrl: './service-provider-detail.component.html',
  styleUrls: ['./service-provider-detail.component.scss']
})
export class ServiceProviderDetailComponent implements OnInit {
  @Output() dataSourceUpdated = new EventEmitter<void>();
  serviceForm: FormGroup = new FormGroup({});
  serviceTypes = ServiceType;
  serviceProviderTypes = ServiceProviderType;
  dialogOpened = true;
  serviceTypesKeys = Object.keys(ServiceType).filter(key => isNaN(+key));
  serviceProviderTypesKeys = Object.keys(ServiceProviderType).filter(key => isNaN(+key));

  constructor(private fb: FormBuilder, private providerService: ServiceProviderService) { }

  ngOnInit(): void {
    this.serviceForm = this.fb.group({
      type: [null, Validators.required],
      providerType: [null, Validators.required],
      name: ['', Validators.required],
      contactName: [''],
      contactPhone: [''],
      contactEmail: ['', [Validators.email]],
      providerBillingAddress: this.fb.group({
        street: ['',],
        city: [''],
        state: [''],
        zipCode: [''],
        country: ['']
      }),
      note: [''],
      paymentMethod: this.fb.group({
        creditCard: [false],
        check: [false],
        cash: [false],
        wireTransfer: [false],
        referenceNumber: ['']
      })
    });
  }

  onSubmit(): void {
    if (this.serviceForm.valid) {
      this.providerService.createProvider(this.serviceForm.value).subscribe({
        next: () => {
          this.dataSourceUpdated.emit();
        }, error: (error) => {
          console.error('Error creating provider: ', error);
        }
      });
    }
  }

  submitForm(): void {
    this.markFormGroupTouched(this.serviceForm);
    this.onSubmit();
  }

  closeDialog(): void {
    this.dialogOpened = false;
  }

  openDialog(): void {
    this.dialogOpened = true;
  }

  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control!.markAsTouched();
      }
    });
  }
}
