<div class="app-container">
  <div class="app-header">
    <app-header></app-header>
  </div>
  <div class="main-content">
    <app-sidebar></app-sidebar>
    <main class="content">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>

