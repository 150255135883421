

<div class="login-container">
    <div class="left-section">
      
    </div>
    <div class="right-section">
    <div class="welcome-section">
      <h1>Welcome to C.A.M.P </h1>
      <button class="login-button" (click)="login()">Log in</button>
    </div>
  </div>
  </div>