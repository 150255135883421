import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceProvider } from './models/service-provider';
import { ServiceProviderService } from './services/service-provider.service';
import { ServiceProviderTableComponent } from './service-provider-table/service-provider-table.component';

import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { ServiceProviderDetailComponent } from './service-provider-detail/service-provider-detail.component';
@Component({
  selector: 'app-service-provider',
  standalone: true,
  imports: [ServiceProviderTableComponent, DialogsModule, ButtonsModule, ServiceProviderDetailComponent, CommonModule],
  templateUrl: './service-provider.component.html',
  styleUrl: './service-provider.component.scss'
})
export class ServiceProviderComponent {
  dataSource: ServiceProvider[] = [];
  @ViewChild('formComponent') formComponent!: ServiceProviderDetailComponent;
  @ViewChild('grid') gridComponent!: ServiceProviderTableComponent;
  public dialogOpened = false;
  constructor(private providerService: ServiceProviderService) {

  }
  ngOnInit(): void {
    this.getProviders();
  }

  getProviders(): void {
    this.providerService.getAllProviders().subscribe((data: ServiceProvider[]) => {
      this.dataSource = data;
    });
  }

  addProvider() {
    this.dialogOpened = true;
  }

  public close(): void {
    this.dialogOpened = false;
    this.getProviders();
  }


  submitForm(): void {
    if (this.formComponent) {
      this.formComponent.submitForm();
    }
  }
}
