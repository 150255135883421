<span class="title">IVICON C.A.M.P</span>
<span class="spacer"></span>
<div class="profile-container">
  <div #anchor class="profile-container" (click)="togglePopup()">
    <img [src]="user?.picture" alt="Profile Picture" class="profile-picture">
  </div>
  <kendo-popup [anchor]="anchor" *ngIf="show" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign">
    <ul class="profile-menu">
      <li>
        <kendo-svg-icon [icon]="userIcon"></kendo-svg-icon>
        Profile
      </li>
      <li (click)="logout()">
        <kendo-svg-icon [icon]="logoutIcon"></kendo-svg-icon>
        Logout
      </li>
    </ul>
  </kendo-popup>
</div>