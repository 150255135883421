import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IconsModule } from "@progress/kendo-angular-icons";
import { CommonModule } from '@angular/common';
import { userIcon,logoutIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  providers: [AuthService],
  imports: [
    PopupModule,
    CommonModule,
    IconsModule
  ]
})
export class HeaderComponent implements OnInit {
  isAuthenticated = false;
  user: any;
  public show: boolean = false;
  public anchorAlign: { horizontal: 'left' | 'center' | 'right'; vertical: 'top' | 'center' | 'bottom' } = { horizontal: 'right', vertical: 'bottom' };
  public popupAlign: { horizontal: 'left' | 'center' | 'right'; vertical: 'top' | 'center' | 'bottom' } = { horizontal: 'right', vertical: 'top' };
  
  
  public userIcon = userIcon;
  public logoutIcon = logoutIcon;
  constructor(public auth: AuthService) {}

  public togglePopup(): void {
    this.show = !this.show;
  }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      if (isAuthenticated) {
        this.auth.getAccessTokenSilently().subscribe();
      }
    });

    this.auth.user$.subscribe(user => {
      this.user = user;
    });
  }

  logout(): void {
    this.auth.logout({
      logoutParams: { returnTo: window.location.origin }
    });
    this.show = false;
  }
}

