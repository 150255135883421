<form [formGroup]="serviceForm" (ngSubmit)="onSubmit()" class="form-container">
  <div class="left-section">
    <div class="form-group">
      <label for="type">Type</label>
      <kendo-dropdownlist [data]="serviceTypesKeys" formControlName="type"
        [defaultItem]="'Select Type'"></kendo-dropdownlist>
    </div>
    <div class="form-group">
      <label for="providerType">Provider Type</label>
      <kendo-dropdownlist [data]="serviceProviderTypesKeys" formControlName="providerType"
        [defaultItem]="'Select Provider Type'"></kendo-dropdownlist>
    </div>
    <div class="form-group">
      <label for="name">Name</label>
      <input kendoTextBox formControlName="name" title="Name" />
    </div>
    <div class="form-group">
      <label for="contactName">Contact Name</label>
      <input kendoTextBox formControlName="contactName" title="Contact Name" />
    </div>
    <div class="form-group">
      <label for="contactPhone">Contact Phone</label>
      <input kendoTextBox formControlName="contactPhone" title="Contact Phone" />
    </div>
    <div class="form-group">
      <label for="contactEmail">Contact Email</label>
      <input kendoTextBox formControlName="contactEmail" type="email" title="Contact Email" />
    </div>
    <div formGroupName="providerBillingAddress">
      <div class="form-group">
        <label for="street">Street</label>
        <input kendoTextBox formControlName="street" title="Street" />
      </div>
      <div class="form-group">
        <label for="city">City</label>
        <input kendoTextBox formControlName="city" title="City" />
      </div>
      <div class="form-group">
        <label for="state">State</label>
        <input kendoTextBox formControlName="state" title="State" />
      </div>
      <div class="form-group">
        <label for="zipCode">Zip Code</label>
        <input kendoTextBox formControlName="zipCode" title="Zip Code" />
      </div>
      <div class="form-group">
        <label for="country">Country</label>
        <input kendoTextBox formControlName="country" title="Country" />
      </div>
    </div>
    <div formGroupName="paymentMethod">
      <label>Payment Methods</label>
      <div class="payment-methods">
        <div class="form-group">
          <input type="checkbox" kendoCheckBox formControlName="creditCard" title="Credit Card" />
          <label>Credit Card</label>
        </div>
        <div class="form-group">
          <input type="checkbox" kendoCheckBox formControlName="check" title="Check" />
          <label>Check</label>
        </div>
        <div class="form-group">
          <input type="checkbox" kendoCheckBox formControlName="cash" title="Cash" />
          <label>Cash</label>
        </div>
        <div class="form-group">
          <input type="checkbox" kendoCheckBox formControlName="wireTransfer" title="Wire Transfer" />
          <label>Wire Transfer</label>
        </div>
      </div>
      <div class="form-group">
        <label for="referenceNumber">Reference Number</label>
        <input kendoTextBox formControlName="referenceNumber" title="Reference Number" />
      </div>
    </div>
  </div>
  <div class="right-section">
    <div class="form-group full-height">
      <label for="note">Note</label>
      <kendo-editor formControlName="note" title="Note"></kendo-editor>
    </div>
  </div>
</form>