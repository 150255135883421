import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  providers: [AuthService]
})
export class LoginComponent implements OnInit {
  returnUrl: string = '/';

  constructor(public auth: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    // Check if there is a return URL
    this.route.queryParams.subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });
  }

  login(): void {
    this.auth.loginWithRedirect({
      appState: { target: this.returnUrl }
    });
  }
}
