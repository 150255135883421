import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';
import { HomeComponent } from './features/home/home.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { LoginComponent } from './core/components/login/login.component';
import { CallbackComponent } from './core/components/login/callback/callback.component';
import { DefaultPageComponent } from './features/default-page/default-page.component';
import { StudentsComponent } from './features/students/students.component';
import { ServiceProviderComponent } from './features/service-provider/service-provider.component';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard],
       children: [
        { path: '', component: DefaultPageComponent },
        { path: 'students', component: StudentsComponent },
        { path: 'providers', component: ServiceProviderComponent},
        {
          path: 'courses',
          loadChildren: () => import('./features/program-course/program-course.module').then(m => m.ProgramCourseModule)
        },
        {
          path: 'programs',
          loadChildren: () => import('./features/education-program/education-program.module').then(m => m.EducationProgramModule)
        },
        {
          path: 'settings',
          loadChildren: () => import('./features/settings/settings.module').then(m => m.SettingsModule)
        },
      ]
    },
    { path: 'login', component: LoginComponent },
    { path: 'callback', component: CallbackComponent },
    { path: '**', component: PageNotFoundComponent }
];

export const routing = RouterModule.forRoot(routes);
